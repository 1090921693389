import { Col } from "react-bootstrap"

export const ProjectCard = ({title, description, imageUrl}) => {

    const image = require(`../assets/images/${imageUrl}`)

    return (
        <Col sm={6} md={4}>
            <div className="project-image-box">
                <img className="project-image-picture" src={image} alt='' />
                <div className="project-content">
                    <h4 className="project-title">{title}</h4>
                    <span className="project-description">{description}</span>
                </div>
            </div>
        </Col>
    )
}