import 'bootstrap/dist/css/bootstrap.min.css'

import { NavBar } from './component/navbar/NavBar'
import { Banner } from './component/Banner'
import { About } from './component/About'
import { Projects } from './component/Projects'
import { ContactUs } from './component/ContactUs'
import { Footer } from './component/Footer'
import { AboutSmall } from './component/AboutSmall'

import './assets/css/template_styles.scss'

function App() {

  const skills = [
    'Проектное внедрение программных продуктов',
    'Настройка и мониторинг производительности системы',
    'Установка, модификация и обновление ПО',
    'Cопровождение и поддержка пользователей IT-систем',
    'Комплексный анализ производительности ПО',
    // '<?php',
    // 'Поддержка сайтов',
    //'npx create-react-app new-proj',
    // 'Воплощаем вашу цифровую мечту в реальность!'
  ]

  return (
    <div className="App">
      <NavBar />
      <Banner skills={skills} />
      <About />
      <Projects />
      <ContactUs />
      <AboutSmall />
      <Footer />
    </div>
  );
}

export default App;
