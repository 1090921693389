import '../assets/css/logotype.scss'

export const Logotype = ({ isBlack }) => {
    return (
        <div className={'ac-logotype ' + (isBlack === true ? 'black-and-white' : '')}>
            <span className='ac-logotype-first'>Аметист</span>
            <span className='ac-logotype-picture'></span>
            <span className='ac-logotype-last'>Компания</span>
        </div>
    )
}