import { Col, Container, Row } from "react-bootstrap"
import '../assets/css/about.scss'

export const About = () => {

    return (
        <section className="ac-about" id="about">
            <Container>
                <Row>
                    <Col>
                        <div className="ac-about-content">
                            <h2 className="ac-about-title">О Компании</h2>
                            <p className="ac-about-description">Компания «Аметист» предоставляет широкий спектр IT услуг.</p>
                            <p className="ac-about-description">Мы понимаем, что каждый бизнес уникален, поэтому наш подход индивидуален и адаптируется под потребности каждого клиента. Мы готовы предложить нашим клиентам глубокий анализ и комплексную оптимизацию бизнес-процессов с помощью программных продуктов 1С и Bitrix.</p>
                            <p className="ac-about-description">Наша команда состоит из опытных и сертифицированных специалистов, готовых помочь вам в решении любых задач. Мы ценим каждого клиента и стремимся к долгосрочному сотрудничеству. Если вы ищете надежного партнера в области IT, обратитесь к нам!</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}