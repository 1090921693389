import { Col, Container, Row } from 'react-bootstrap'
import { Logotype } from './Logotype'
import { SocialIcons } from './SocialIcons'
//import { MailchimpForm } from './MailchimpForm'

import '../assets/css/footer.scss'

export const Footer = () => {
    return (
        <footer className='ac-footer'>
            <Container>
                <Row className='align-items-center'>
                    {/* <MailchimpForm /> */}
                    <Col size={12} sm={6}>
                        <Logotype isBlack={true} />
                    </Col>
                    <Col size={12} sm={6} className='text-center text-sm-end'>
                        <div className='ac-footer-social-icon'>
                            <SocialIcons />
                        </div>
                        <p>Copyright 2024. All Rights Reserved</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}