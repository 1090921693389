import { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import imgContactUs from '../assets/images/ametist-it_contact_form5.png'
import '../assets/css/contact_us.scss'

export const ContactUs = () => {
    const initialFormFields = {
        firstName: '',
        secondName: '',
        email: '',
        phone: '',
        message: ''
    }

    const [formDetails, setFormDetails] = useState(initialFormFields)
    const [buttonText, setButtonText] = useState('Отправить')
    const [status, setStatus] = useState({
        success: null,
        message: '',
    })

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setButtonText('Отправляем...')

        let response = await fetch('/contact', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(formDetails),
        })

        setButtonText('Отправлено')

        let result = await response.json();

        setFormDetails(initialFormFields);

        if (result && typeof result.code !== 'undefined' && result.code === 200) {
            setStatus({ success: true, message: 'Сообщение отправлено.'});
        } else { 
            setStatus({ success: false, message: 'Что-то пошло не так. Сообщение не отправлено. Попробуйте позже.'});
        }
    }

    return (
        <section className="ac-contactus" id="contactus">
            <Container>
                <Row className="align-items-justify">
                    <Col size={12} md={6} className="d-flex justify-content-center">
                        <img className="ac-contactus-picture" src={imgContactUs} alt="Связаться с нами" />
                    </Col>
                    <Col size={12} md={6}>
                        <h2 className="ac-contactus-title">Связаться с нами</h2>

                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col size={12} sm={6} className='px-1'>
                                    <input type='text' value={formDetails.firstName} placeholder='Имя' onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                                </Col>

                                <Col size={12} sm={6} className='px-1'>
                                    <input type='text' value={formDetails.lastName} placeholder='Фамилия' onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                                </Col>

                                <Col size={12} sm={6} className='px-1'>
                                    <input type='text' value={formDetails.email} placeholder='Email' onChange={(e) => onFormUpdate('email', e.target.value)} />
                                </Col>

                                <Col size={12} sm={6} className='px-1'>
                                    <input type='text' value={formDetails.phone} placeholder='Телефон' onChange={(e) => onFormUpdate('phone', e.target.value)} />
                                </Col>

                                <Col size={12} className='px-1'>
                                    <textarea rows='6' value={formDetails.message} placeholder='Сообщение' onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                                    <button type="submit">
                                        <span>{buttonText}</span>
                                    </button>
                                </Col>

                                {
                                    status.message &&
                                    <Row>
                                        <Col className={"ac-contactus-message-sent " + (status.success ? 'success' : 'danger')}>
                                            <p>{status.message}</p>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}