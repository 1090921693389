import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
//import { ArrowRightCircle } from "react-bootstrap-icons"
import '../assets/css/banner.scss'

import imageAmetist from '../assets/images/amethyst-it.ru.jpg'

import 'animate.css'
import TrackVisibility from 'react-on-screen'

export const Banner = ({skills}) => {

    const [loopNumber, setLoopNumber] = useState(0)
    const [isReducing, setIsReducing] = useState(false)
    const [text, setText] = useState('')
    const [delta, setDelta] = useState(300 - Math.random() * 100)
    const period = 200

    useEffect(() => {
        let ticker = setInterval(() => {
            tick()
        }, delta)

        return () => { clearInterval(ticker) }
    })

    const tick = () => {
        let i = loopNumber % skills.length
        let fullText = skills[i]
        let updatedText = isReducing
            ? fullText.substring(0, text.length - 1)
            : fullText.substring(0, text.length + 1)

        setText(updatedText)

        if(isReducing) {
            setDelta(prevData => prevData / 2)
        }

        if(!isReducing && updatedText === fullText) {
            setIsReducing(true)
            setDelta(period)
        } else if (isReducing && updatedText === '') {
            setIsReducing(false)
            setLoopNumber(loopNumber + 1)
            setDelta(500)
        }
    }

    // const scrollToContactUs = ref => {
    //     if (ref && ref.current) {
    //         ref.current.scrollIntoView({ behavior: 'smooth', block: 'contactus' })
    //     }
    // }

    return (
        <section className="ac-banner" id="home">
            <Container>
                <Row className="align-items-center ac-banner-content">
                    <Col xs={12} md={7} xl={7}>
                        <div className="ac-banner-content">
                            <span className="ac-banner-tag-line">IT компания</span>
                            
                            <TrackVisibility>
                                {({ isVisible }) => (
                                    <div className={isVisible ? 'animate__animated animate__fadeInLeft' : ''}>
                                        <h1 className="ac-banner-title">{`Аметист`}<br />
                                            <span className="ac-banner-text-rotate">
                                                <span className="ac-banner-text-wrap">{text}</span>
                                            </span>
                                        </h1>

                                        <p className="ac-banner-description">
                                            Воплощаем вашу цифровую мечту в реальность!
                                        </p>
                                    </div>
                                )}
                            </TrackVisibility>
                    
                            <br />
                            <br />
                            {/* <button className="ac-banner-button-connect"
                                ref={scrollToContactUs}>Связаться <ArrowRightCircle size={25} />
                            </button> */}
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <div className="ac-banner-picture">
                            <img src={imageAmetist} alt="Компания Аметист" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}