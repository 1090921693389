import { AiOutlineHome } from 'react-icons/ai'
import { BiBookAlt } from 'react-icons/bi'
import { RiServiceLine } from 'react-icons/ri'
import { BiUser } from 'react-icons/bi'

import './NavBarFly.scss'
import { Nav } from 'react-bootstrap'

export const NavBarFly = ({ activeLink, setActiveLink }) => {
    return (
        <nav className='ac-navbar-fly'>
            <Nav className="ac-navbar-fly__content">
                <Nav.Link className={activeLink === 'home' ? 'active' : ''} href="#home" alt="Начало" onClick={() => setActiveLink('home')}><AiOutlineHome size={'1.2em'} /></Nav.Link>
                <Nav.Link className={activeLink === 'skills' ? 'active' : ''} href="#about" alt="О компании" onClick={() => setActiveLink('skills')}><BiBookAlt size={'1.2em'} /></Nav.Link>
                <Nav.Link className={activeLink === 'projects' ? 'active' : ''} href="#projects" alt="Оказываем услуги" onClick={() => setActiveLink('projects')}><RiServiceLine size={'1.2em'} /></Nav.Link>
                <Nav.Link className={activeLink === 'contactus' ? 'active' : ''} href="#contactus" alt="Связаться с нами" onClick={() => setActiveLink('caontactus')}><BiUser size={'1.2em'} /></Nav.Link>
            </Nav>
        </nav>
    )
}