import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { HashLink } from 'react-router-hash-link'
import { BrowserRouter as Router } from "react-router-dom";
import { Logotype } from '../Logotype'
import './NavBar.scss'
import { useEffect, useState } from 'react'
import { SocialIcons } from '../SocialIcons'
import { NavBarFly } from './NavBarFly'

export const NavBar = ({ social }) => {

  const [activeLink, setActiveLink] = useState('home')
  const [scrolled, setScrolled] = useState(false)

  const links = [
    {
      code: 'home',
      title: 'Начало'
    },
    {
      code: 'about',
      title: 'О компании'
    },
    {
      code: 'projects',
      title: 'Проекты'
    }
  ]

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  })

  return(
    <Router>
      <NavBarFly links={links} activeLink={activeLink} setActiveLink={e => setActiveLink(e)} />
      <Navbar expand='md' className={scrolled ? 'ac-navbar scrolled' : 'ac-navbar'}>
        <Container>
          <Navbar.Brand href='#home' className='ac-navbar-brand'>
            <Logotype />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls='basic-navbar-nav'>
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>

          <Navbar.Collapse id='basic-navbar-nav' className='ac-navbar-collapse'>
            <Nav className='ac-navbar-menu justify-content-end'>
              {
                links.map((link, index) => {
                  return (
                    <Nav.Link 
                      key={index} 
                      href={'#' + link.code} 
                      className={'ac-navbar-link ' + (activeLink === link.code ? 'active' : '')} 
                      onClick={() => setActiveLink(link.code)}>
                        {link.title}
                    </Nav.Link>
                )})
              }
            </Nav>

            <div className='ac-navbar-text'>
              <div className="ac-navbar-social">
                <SocialIcons />
              </div>
            </div>

            <HashLink to='#contactus' className='ac-navbar-connect'>
              <button className='ac-navbar-connect__button' onClick={() => setActiveLink('contactus')}>
                <span>Свяжитесь с нами</span>
              </button>
            </HashLink>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}