import { Container, Tab, Col, Row, Nav } from 'react-bootstrap'
import { ProjectCard } from './ProjectCard'
import '../assets/css/projects.scss'

import 'animate.css'
import TrackVisibility from 'react-on-screen'

export const Projects = () => {

    const projects = [
        {
            title: 'Интернет-магазин ESTIMA',
            description: 'Design & Development',
            imageUrl: 'project-image-001.png',
        },
        {
            title: 'Интернет-магазин Тайфун',
            description: 'Support',
            imageUrl: 'project-image-002.png',
        },
        {
            title: 'Главный сайт компании ESTIMA',
            description: 'Design & Development',
            imageUrl: 'project-image-003.png',
        },
        {
            title: 'Главный сайт компании ESTIMA',
            description: 'Design & Development',
            imageUrl: 'project-image-003.png',
        },
        {
            title: 'Интернет-магазин ESTIMA',
            description: 'Design & Development',
            imageUrl: 'project-image-001.png',
        },
        {
            title: 'Интернет-магазин Тайфун',
            description: 'Support',
            imageUrl: 'project-image-002.png',
        },
    ]

    return (
        <section className="ac-projects" id="projects">
            <Container>
                <Row>
                    <Col>
                        <TrackVisibility>
                            {({ isVisible }) => ( 
                                <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                                    <h2 className="ac-projects-title">Оказываем услуги по направлениям</h2>
                                </div>
                            )}
                        </TrackVisibility>

                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                            <Nav variant="pills" className="mb-5 justify-content-center align-items-center">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Услуги</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Web проекты</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">1С Внедрение</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content id="slideInUp">
                                <Tab.Pane eventKey="first" className="ac-projects-servicelist">
                                    <Row>
                                        <Col className='p-3 mb-1 mt-1'>Оптимизация скорости работы и комплексный анализ производительности вашего ПО</Col>
                                        <Col className='p-3 mb-1 mt-1'>Разработка, доработка, модификация, устранение ошибок ПО</Col>
                                        <Col className='p-3 mb-1 mt-1'>Проектное внедрение программных продуктов и автоматизация Вашего бизнеса</Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className='p-3 mb-1 mt-1'>Установка, модификация и обновление программного обеспечения и оборудования</Col>
                                        <Col className='p-3 mb-1 mt-1'>Настройка и мониторинг производительности системы для оптимизации работы и улучшения ее эффективности</Col>
                                        <Col className='p-3 mb-1 mt-1'>Обеспечение бесперебойной работы IT-инфраструктуры</Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className='p-3 mb-1 mt-1'>Консультации, сопровождение и поддержка пользователей IT-систем</Col>
                                        <Col className='p-3 mb-1 mt-1'>Web-разработка</Col>
                                        <Col className='p-3 mb-1 mt-1'>Другие услуги в области IT</Col>
                                    </Row>
                                    
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Row>
                                        {
                                            projects.map((projects, index) => {
                                                return (
                                                    <ProjectCard key={index} {...projects} />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <h5>Мы предлагаем полный спектр услуг в области 1С</h5>
                                    <ul className="ac-contactus-list">
                                        <li>Настройка и доработка вашей конфигурации 1С;</li>
                                        <li>Проектное внедрение программных продуктов 1С;</li>
                                        <li>Автоматизация Ваших бизнес-процессов;</li>
                                        <li>Консультации по ведению бухгалтерского, управленческого, оперативного и налогового учета в программных продуктах 1С;</li>
                                        <li>Сопровождение и поддержка пользователей 1С 24/7;</li>
                                        <li>Интеграция Битрикс24 и 1С;</li>
                                        <li>Переход на 1С с иностранного ПО (Oracle, SAP);</li>
                                    </ul>
                                    
                                    <p>Мы ценим каждого клиента и стремимся установить с ним долгосрочное и взаимовыгодное партнерство. Мы готовы предоставить нашим клиентам качественную и своевременную техническую поддержку в любое время.</p>

                                    <p>Если вы ищете надежного и компетентного партнера в области 1С, обратитесь к нам. Мы готовы помочь вам решить любые задачи и превратить ваш бизнес в эффективную и прибыльную организацию.</p>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}