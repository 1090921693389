import '../assets/css/social_icon.scss'

export const SocialIcons = () => {
    const social = [
        // {
        //     code: 'VK',
        //     name: 'ВКонтакте',
        //     imageUrl: 'VK_BW_Compact_Logo.svg',
        //     link: '',
        // },
        // {
        //     code: 'VK',
        //     name: 'ВКонтакте',
        //     imageUrl: 'VK_BW_Compact_Logo.svg',
        //     link: '',
        // },
        // {
        //     code: 'VK',
        //     name: 'ВКонтакте',
        //     imageUrl: 'VK_BW_Compact_Logo.svg',
        //     link: '',
        // },
    ]

    return (
        <>
        {
            social.map((item, index) => {
                return (
                <a key={index} className='ac-social-icon' href={item.link}>
                    <img src={require('../assets/images/' + item.imageUrl)} alt={item.name} />
                </a>
                )
            })
        }
        </>
    )
}