import { Col, Container, Row } from "react-bootstrap"
import '../assets/css/about-small.scss'

export const AboutSmall = () => {
    return (
        <Container className="ac-about-small" id="rekvi">
            <Row className='align-items-center'>
                <Col lg={12}>
                    <div className="ac-about-small__container wow slideInUp">
                        <Row>
                            <Col lg={12} md={6} xl={5}>
                                <h3><span>Контакты</span><br />Оставайтесь с нами на связи</h3>
                                <br />
                                <div className="ac-about-small__list"><strong>Фактический адрес:</strong><br />142410, Московская обл., г. о. Богородский, г. Ногинск, ул. Бетонная, д.1, комн. 27</div>
                                <div className="ac-about-small__list"><strong>Email:</strong><br /><a className="ac-about-small__link" href="email:info@ametist-it.ru">info@ametist-it.ru</a></div>
                            </Col>
                            <Col md={6} xl={7} className="ac-about-small__cdata">
                                <h4>Компания <span className="ac-color-violet">Аметист</span></h4>
                                <ul className="ac-about-small__rekvi">
                                    <li>Общество с ограниченной ответственностью "Аметист"</li>
                                    <li><strong>ОГРН:</strong> 1235000053419</li>
                                    <li><strong>ИНН:</strong> 5031150799</li>
                                    <li><strong>КПП:</strong> 503101001</li>
                                    <li><strong>Расч. счет:</strong> 40702810040000008674</li>
                                    <li><strong>Юридический адрес:</strong> 142410, Московская обл., г. о. Богородский, г. Ногинск, ул. Бетонная, д.1, комн. 27</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}